@mixin smooth($speed, $type) {
	-webkit-transition: $speed $type;
	-moz-transition: $speed $type;
	-o-transition: $speed $type;
	transition: $speed $type; }

@mixin transition($in) {
	transition: $in;
	-webkit-transition: $in;
	-moz-transition: $in;
	-o-transition: $in;
	-ms-transition: $in; }

@mixin transform($in) {
	transform: $in;
	-webkit-transform: $in;
	-moz-transform: $in;
	-o-transform: $in;
	-ms-transform: $in; }

$button-size: 20px;
$transition: 0.3s;
$color: black;

@mixin line {
	display: inline-block;
	width: $button-size;
	height: $button-size/8;
	background: $color;
	border-radius: $button-size/14;
	transition: $transition; }
