.activity-wrap {
	max-width: 710px;
	min-height: 200px;
	overflow: auto;
	padding: 85px 100px 85px;
	background-color: white;
	border: 1px solid $main-pink;

	.title {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.21;
		margin-bottom: 40px; }

	.time {
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 1px;
		color: $main-pink;
		line-height: 1.5;
		margin-bottom: 40px; }

	.detail {
		font-size: 14px;
		line-height: 1.86;
		margin-bottom: 40px;
		letter-spacing: 1px; }

	.location {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.61;
		margin-bottom: 40px; }

	table {
		text-align: left;
		font-size: 14px;
		line-height: 1.6;

		th {
			min-width: 35px;
			font-weight: 600;
			line-height: 1.5;
			color: $main-pink;
			padding: 0px 25px 20px 0px; } } }

@media all and (max-width: 1200px) {
	.activity-wrap {
		max-width: 75%;
		padding: 55px 65px 75px; } }


@media all and (max-width: 600px) {
	.activity-wrap {
		max-width: 88vw !important;
		margin-top: 55px;
		padding: 44px 4vw 53px; } }

