.code-header-container {
	min-height: 300px;
	max-width: 1110px;
	padding: 20px;

	h1 {
		font-size: 72px;
		font-weight: 800;
		letter-spacing: 1px;
		color: $main-pink;
		margin: 150px 0px 50px;
		line-height: 1.5;

		span {
			display: block;
			margin-top: -20px;
			font-size: 69px;
			letter-spacing: 1px; } } }

.code_of_conduct {
	max-width: 1110px;
	padding: 20px;
	font-size: 14px;
	line-height: 1.86;
	color: $font-dark; }

@media all and (max-width: 600px) {
	h1 {
		font-size: 30px !important;
		line-height: 1.8 !important;

		span {
			font-size: 27px !important; } }
	.code_of_conduct {
		font-size: $larger-font; } }

