@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800,900);


// @font-face
// 	font-family: 'Raleway'
// 	src: url('https://fonts.googleapis.com/css?family=Raleway')
// 	font-weight: normal
// 	font-style: normal

$font-dark: #212624;
$main-pink: #ec6d65;
$light-pink: #ef8d81;
$fade-pink: rgba(246, 187, 175, 0.56);
$larger-font: 15px;

$primary-font-style:  "Raleway", "PingFangTC", "微軟正黑體", "Open Sans", "Lato", "Noto", "Helvetica","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Arial", "Microsoft JhengHei", sans-serif;
$secondary-font-style: "Helvetica", "Lato", "Noto","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
