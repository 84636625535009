.table-wrap {
	max-width: 1110px;
	padding: 35px 28px 50px 90px;
	background-color: white;

	.schedule {
		width: 990px;

		#main-nav, #vice-nav {
			width: 50%; }

		.border-right {
			border-right: 1px solid $main-pink; }
		.th-img {
			margin-right: 5px; }

		.time {
			position: absolute;
			font-size: 18px;
			font-weight: bold;
			letter-spacing: 1px;
			color: $main-pink; }

		.large-row {
			height: 60px;
			line-height: 60px; }

		th {
			position: relative;
			background-color: $fade-pink;
			border-top: 1px solid $main-pink;
			border-bottom: 1px solid $main-pink;
			// border-style: solid
			// border-color: $main-pink transparent $main-pink transparent
			// border-width: 1px
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 1px;
			color: $main-pink;
			height: 40px;
			line-height: 40px;
			vertical-align: middle;

			img {
				vertical-align: middle; }

			.time {
				top: -20px;
				left: -65px; }
			.time-up {
				top: -30px;
				left: -65px; }
			.time-down {
				top: 20px;
				left: -65px; }
			.time-down-large {
				top: 30px;
				left: -65px; } }

		td {
			position: relative;
			padding: 15px;
			width: 50%;
			font-size: 13px;
			line-height: 1.6;
			border-top: 1px solid $main-pink;
			border-bottom: 1px solid $main-pink;
			// border-style: solid
			// border-color: $main-pink transparent $main-pink transparent
			// border-width: 1px
			vertical-align: middle;

			.time {
				top: -16px;
				left: -65px; }
			.time-up {
				top: -30px;
				left: -65px; }


			.title {
				font-size: 16px;
				font-weight: 600; } } } }

.schedule-group {
	max-width: 1000px;
	padding: 50px;
	min-height: 200px;
	overflow: hidden;

	.schedule-title {
		color: $main-pink;
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 1;

		.large {
			font-size: 30px; }

		.black {
			color: $font-dark;
			font-size: 16px;
			font-weight: normal; }

		.italic {
			font-style: italic;
			font-weight: 400;
			letter-spacing: 1px; } }

	.single-sponsor {
		min-height: 100px;
		overflow: hidden;
		margin-top: 40px;

		.img-wrap {
			float: left;
			width: 150px;
			text-align: center;
			margin: 0px 25px 0px 0px;

			img {
				width: 150px;
				vertical-align: center; } }

		.info-wrap {
			float: left;
			max-width: 700px;

			.title {
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 1px;
				margin-bottom: 10px; }

			.detail {
				font-size: 14px;
				line-height: 1.86; } } } }

@media all and (max-width: 1200px) {
	.table-wrap {
		max-width: 87%;
		padding: 32px 6px 50px 66px;
		background-color: white;

		.schedule {
			width: 97%;

			td {
				position: relative;
				padding: 15px;
				width: 50%;
				font-size: 13px;
				line-height: 1.6;
				border-style: solid;
				border-color: $main-pink transparent $main-pink transparent;
				border-width: 1px;

				.time {
					top: -16px;
					left: -65px; }

				.title {
					font-size: 15px;
					font-weight: 600; } } } } }

@media all and (max-width: 500px) {
	.table-wrap {
		width: 60%;
		padding: 32px 8px 50px 60px;
		margin-top: 50px;
		.schedule {
			width: 100%;
			.time {
				font-size: 15px;
				left: -55px !important; }
			.border-right {
				border-right: 0px solid $main-pink; }
			.main-th {
				height: 40px !important;
				line-height: 40px; }
			.th-img {
				display: none; }

			th {
				font-size: $larger-font;
				height: 30px !important;
				line-height: 30px !important; }
			td {
				width: 100%;
				font-size: $larger-font; } } } }

