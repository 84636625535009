.header-img {
	width: 338px;
	height: 326px;
	margin: 200px auto 6px; }

.header-info {
	margin: 10px auto;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	color: $font-dark;

	p {
		margin: 6px;
		font-weight: 400; }

	.count-down {
		font-size: 36px;
		font-weight: 500;
		letter-spacing: 1px;
		line-height: 45px;
		margin: 26px; }

	span {
		color: #ec6d65;
		font-weight: 500; }

	.on-sale {
		font-style: italic;
		color: $main-pink;
		font-size: 14px;
		font-weight: 700;
		margin: 5px; } }
.home-intro-p {
	max-width: 800px;
	margin: 30px auto;
	line-height: 1.8;
	color: $font-dark;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 1px;
	padding: 40px; }


.paragraph-link-container {
	max-width: 1000px;
	min-height: 400px;
	overflow: hidden;


	.paragraph-link {
		max-width: 311px;
		float: left;
		margin: 25px 10px;
		position: relative;

		.ch-title {
			font-size: 18px;
			font-weight: 600; }
		.en-title {
			font-size: 14px;
			font-weight: bold;
			color: $main-pink;
			letter-spacing: 0.8px;
			margin: 5px; } }

	.link-detail {
		max-width: 227px;
		margin: 20px;
		padding: 26px 22px 11px;
		background-color: white;
		text-align: left;
		box-shadow: 0 2px 8px 0 #f6bbaf;
		border: solid 1px #ec6d65;
		letter-spacing: 1px;

		&:hover div {
			opacity: 1; } }

	.arrow {
		float:right {}
		text-align: right;
		opacity: 0;
		color: $main-pink;
		margin: 38px -10px 0px 0px; }

	.date {
		letter-spacing: 1px;
		font-weight: bold;
		margin: 6px 0px;
		font-size: 13px; }

	.time {
		color: $main-pink;
		margin: 3px 0px;
		letter-spacing: 1px;
		font-weight: bold;
		line-height: 1.64; }

	.detail {
		margin: 20px 0px 42px;
		line-height: 1.64;
		font-size: 14px; }

	.position {
		color: $main-pink;
		margin: 6px 0px;
		font-weight: bold; }

	.position-detail {
		font-size: 14px;
		margin: 5px 0px 0px;
		line-height: 1.64; } }

.map-container {
	max-width: 1200px;
	margin-top: 50px;
	min-height: 200px;
	overflow: hidden;

	img, div {
		float: left; }

	.map-intro-wrap {
		max-width: 653px;
		padding: 30px 0px 0px 130px;

		.map-intro-title {
			font-size: 24px;
			font-weight: 600;
			line-height: 1.21;
			margin-bottom: 20px; }

		.map-intro-addr {
			font-size: 18px;
			font-weight: 600;
			line-height: 1.61;
			color: $main-pink;
			margin-bottom: 20px; }

		.map-detail {
			font-size: 14px;
			line-height: 1.86;
			margin-bottom: 20px; }

		button {
			float: right;
			width: 150px; } } }

.sponsor-wrap {
	max-width: 1200px;
	padding: 50px;
	min-height: 200px;
	overflow: hidden;

	.sponsor-title {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.21;
		margin: 10px 0px; }

	.sponsor-intro {
		font-size: 14px;
		line-height: 1.86;
		letter-spacing: 1px; }

	.sponsor-group-wrap {
		min-height: 200px;
		overflow: hidden;

		.title {
			font-size: 20px;
			font-weight: 600;
			letter-spacing: 2px;
			color: $main-pink;
			margin: 30px 0px 10px 0px;
			letter-spacing: 2px; }

		.large {
			font-size: 30px; }

		.black {
			color: black;
			font-size: 14px;
			font-weight: normal;
			letter-spacing: 0.8px; }

		.italic {
			font-weight: 400;
			font-style: italic;
			letter-spacing: 1px; }

		img {
			float: left;
			margin: 15px 15px 0px 0px;
			background-color: white;
			border: 1px solid #dadada;
			&:hover {
				border: 1px solid $main-pink; } } }

	.gold {
		img {
			width: 188px;
			height: 94px;
			padding: 5px 11px;
			background-color: white; } }

	.silver, .bronze, .special {
		img {
			width: 138px;
			height: 74px;
			padding: 10px 17px; } } }

.subscribe-wrap {
	background-color: white;
	padding: 40px 20px;

	.title {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.21;
		letter-spacing: 1px;
		margin-bottom: 16px; }

	.detail {
		font-size: 14px;
		line-height: 1.86;
		max-width: 750px;
		margin-bottom: 22px; }

	.input-wrap {
		background-color: $main-pink;
		width: 470px;
		height: 52px;

		input {
			padding: 2px 11px;
			height: 40px;
			width: 314px;
			margin: 2px;
			font-size: 14px; } } }

@media all and (max-width: 1200px) {
	.home-buy-ticket-btn {
		display: none; }
	.paragraph-link-container {
		max-width: 1000px;
		min-height: 400px;
		overflow: hidden;


		.paragraph-link {
			margin: 0px;
			width: 33%;

			.link-detail {
				margin: 10px; }

			.arrow {
				margin: 0px;
				opacity: 1; } } }
	.map-container {

		img {
			width: 410px;
			margin: 0px auto;
			display: block; }

		img, div {
			float: initial; }

		.map-intro-wrap {
			margin: 0px auto;
			padding: 40px;
			button {
				float: initial;
				margin: 0px auto;
				display: block; }

			.map-intro-title {
				text-align: center; }

			.map-intro-addr {
				text-align: center; } } }

	.sponsor-wrap {
		padding: 50px 50px 0px 50px;
		margin-top: 0px; }

	.gold {
		img {
			width: 28% !important;
			height: auto !important; }
		.three-rightest {
			margin-right: 0px !important; } }

	.silver img, .bronze img, .special img {
		width: 17.5% !important;
		height: auto !important; }
	.four-rightest {
		margin-right: 0px !important; } }


@media all and (max-width: 600px) {
	.header-img {
		width: 60%;
		height: auto;
		margin: 130px auto 30px;
		img {
			width: 100%; } }
	.header-info {
		.count-down {
			font-size: 33px; }
		p {
			line-height: 22px; } }
	.home-intro-p {
		font-size: $larger-font;
		margin: 30px auto -40px; }
	.paragraph-link-container {
		.paragraph-link {
			float: initial;
			margin: 80px auto 0px;
			width: 90%;
			.link-detail {
				margin: 10px auto;
				max-width: 300px; } }
		.detail {
			font-size: $larger-font; } }

	.map-container {
		img {
			width: 90%; }

		.map-intro-wrap {
			padding: 40px 20px 40px 20px;
			.map-intro-title {
				font-size: 20px; }
			.map-intro-addr {
				font-size: 14px; }
			.map-detail {
				font-size: $larger-font; } } }



	.sponsor-group-wrap {
		.black {
			display: block;
			line-height: 2; } }
	.sponsor-wrap {
		padding: 50px 20px 40px 20px;
		.sponsor-intro {
			font-size: $larger-font; } }

	.gold img {
		width: 36% !important;
		height: auto !important; }

	.gold .three-rightest, .four-rightest {
		margin-right: 15px !important; }

	.silver img, .bronze img, .special img {
		width: 31% !important;
		height: auto !important; }

	.subscribe-wrap {
		.input-wrap {
			background-color: $main-pink;
			width: 285px;
			height: 52px;

			input {
				padding: 2px 11px;
				height: 40px;
				width: 170px;
				margin: 2px;
				border-radius: 0;
				border: none;
				-webkit-appearance: none; }

			button {
				width: 75px !important; } } } }
