.mobile-nav {
	display: none;
	opacity: 0;
	@include smooth(0.5s, ease-in-out); }

.desktop-nav {
	opacity: 1;
	@include smooth(0.5s, ease-in-out); }

nav {
	width: 100%;
	position: fixed;
	top: 0px;
	letter-spacing: 2px;
	z-index: 100000000;
	height: auto;
	overflow: hidden;
	@include smooth(0.5s, ease-in-out);

	.nav-logo {
		margin: 52px 0px 0px 52px;
		width: 120px;
		height: 42px;
		@include smooth(0.5s, ease-in-out); }

	.ch-en {
		margin: 27px 64px 0px 0px;
		float: right;
		font-family: $primary-font-style;
		li {
			margin-left: 17px;
			float: left; } }

	#desktop-nav-wrap {
		max-width: 1200px;
		float: right;
		padding: 0px 5px 11px 0px;
		margin-top: -18px;



		.website-nav {
			margin: -15px 0px 0px 0px;
			float: right;
			li {
				float: left;
				margin-right: 30px;
				position: relative;

				&:after {
					content: "";
					position: absolute;
					width: 0px;
					height: 1px;
					background-color: red;
					top: 23px;
					left: 1px; } }

			.three-w {
				&:hover {
					&:after {
						width: 50px; } } }
			.two-w {
				&:hover {
					&:after {
						width: 32px; } } } }


		.out-link {
			margin: -15px 0px 0px 0px;
			float: right;
			li {
				float: left;
				margin-right: 16px; } }

		.fa-youtube-play:hover {
			color: #e12f2f; }

		.fa-envelope:hover {
			color: $main-pink; }

		.buy-ticket-btn {
			margin: -25px 60px 0px 0px;
			font-family: $primary-font-style;
			float: right;
			width: 119px;
			height: 40px;
			background-color: #cce9de;
			border: solid 1px #84ad9e;
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 1px;
			color: #84ad9e; } } }

@media all and (max-width: 1200px) {
	.desktop-nav {
		display: none;
		opacity: 0; }

	#mobile-nav-logo {
		margin: 0px; }

	#mobile-nav-wrap {
		overflow: auto;
		height: 85vh; }

	.mobile-nav-open {
		height: 100vh !important; }

	.mobile-nav {
		display: initial;
		opacity: 1;
		position: fixed;
		height: 30px;
		width: 100vw;
		background-color: #f7fbf9;
		padding: 20px 22px;
		top: 0px;

		.website-nav {
			text-align: center;
			min-height: 200px;
			overflow: hidden;
			padding: 10px 0px;
			margin-left: -35px;
			li {
				position: relative;
				font-size: 18px;
				letter-spacing: 2px;
				margin: 40px auto 0px; }
			.three-w {
				width: 75px;
				&:after {
					content: "";
					position: absolute;
					width: 75%;
					height: 1px;
					background-color: red;
					top: 23px;
					left: 8px;
					display: none; } }
			.two-w {
				width: 45px;
				&:after {
					content: "";
					position: absolute;
					width: 82%;
					height: 1px;
					background-color: red;
					top: 23px;
					left: 3px;
					display: none; } }
			li:hover {
				&:after {
					display: initial; } }
			li:focus {
				&:after {
					display: initial; } }
			li:active {
				&:after {
					display: initial; } } }


		.out-link {
			margin: 50px auto 0px;
			width: 265px;
			font-size: 20px;
			li {
				float: left;
				margin: 0px 18px; }

			.fa {
				font-size: 25px; }
			.fa-envelope {
				font-size: 23px; } }

		button {
			margin: 40px auto 0px;
			font-family: $primary-font-style;
			background-color: #ffe9e9;
			border: solid 1px $main-pink;
			color: $main-pink; } }

	// #burger
	// 	width: 20px
	// 	height: 10px
	// 	position: relative
	// 	float: right
	// 	right: 60px
	// 	margin: 10px 0px
	// 	-webkit-transform: rotate(0deg)
	// 	-moz-transform: rotate(0deg)
	// 	-o-transform: rotate(0deg)
	// 	transform: rotate(0deg)
	// 	cursor: pointer
	// 	@include smooth(0.2s, ease-in-out);

	// #burger span
	// 	display: block
	// 	position: absolute
	// 	height: 2px
	// 	width: 100%
	// 	background: black
	// 	border-radius: 10px
	// 	opacity: 1
	// 	left: 0
	// 	-webkit-transform: rotate(0deg)
	// 	-moz-transform: rotate(0deg)
	// 	-o-transform: rotate(0deg)
	// 	transform: rotate(0deg)
	// 	@include smooth(0.1s, ease-in-out);

	// #burger span:nth-child(1)
	// 	top: 0px

	// #burger span:nth-child(2),#nav-icon3 span:nth-child(3)
	// 	top: 5px
	// 	width: 80%

	// #burger span:nth-child(4)
	// 	top: 10px

	// #burger.open span:nth-child(1),#burger.open span:nth-child(4)
	// 	top: 5px
	// 	width: 0%
	// 	left: 50%

	// #burger.open span:nth-child(2)
	// 	width: 100%
	// 	-webkit-transform: rotate(45deg)
	// 	-moz-transform: rotate(45deg)
	// 	-o-transform: rotate(45deg)
	// 	transform: rotate(45deg)

	// #burger.open span:nth-child(3)
	// 	width: 100%
	// 	-webkit-transform: rotate(-45deg)
	// 	-moz-transform: rotate(-45deg)
	// 	-o-transform: rotate(-45deg)
	// 	transform: rotate(-45deg)
	// 	top: 5px

	#burger {
		width: 40px;
		height: 50px;
		position: relative;
		float: right;
		right: 40px;
		margin: -9px 0px;
		background-color: transparent;
		border: none; }


	.lines-button {
 		display: inline-block;
 		padding: $button-size/2 $button-size/4;
 		transition: .3s;
 		cursor: pointer;
 		user-select: none;
 		border-radius: $button-size/7;
 		&:hover {
 			opacity: 1;
  		&:active {
  			transition: 0;
  			background: rgba(0,0,0,0.1); } } }



	.lines {
		//create middle line
		position: relative;
		@include line();
		&:before {
			top: $button-size/3; }

		&:after {
			top: -$button-size/3; }

		&:before, &:after {
			@include line();
			position: absolute;
			left: 0;
			content: '';
			transform-origin: $button-size/14 center; } }




	.lines-button.x2 .lines {
		transition: background 0.3s 0.6s ease;
		&:before, &:after {
			//set transform origin back to center
			transform-origin: 50% 50%;
			transition: top 0.3s 0.6s ease, transform 0.3s ease; } }

	.lines-button.x2.close .lines {
		transition: background .3s 0s ease;
		background: transparent;

		&:before, &:after {
			transition: top .3s ease, transform .3s .5s ease;
			top: 0;
			width: $button-size; }
		&:before {
			transform: rotate3d(0,0,1,45deg); }
		&:after {
			transform: rotate3d(0,0,1,-45deg); } } }




