.about-container {
	max-width: 1080px;
	min-height: 100px;
	overflow: hidden;
	padding: 50px;

	.title {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.21;
		margin-bottom: 20px; }

	.detail {
		font-size: 14px;
		line-height: 1.86; }
	.content-wrap {
		padding: 10px; } }



.about-container-left {
	.content-wrap, img {
		float: left;
		margin-top: -60px; }

	img {
		width: 470px;
		margin: 0px 0px 0px 58px;
		box-shadow: 9px 9px 22px 0 rgba(132, 173, 158, 0.5); }

	div {
		max-width: 520px; } }

.about-container-right {
	.content-wrap, img {
		float: right; }

	img {
		width: 260px;
		margin: 0px 111px 0px 50px; }

	div {
		max-width: 620px; } }

.qa-container {
	max-width: 1080px;
	margin-top: 100px;
	padding: 50px;

	.title {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.21; }

	.question {
		font-size: 18px;
		font-weight: 600;
		color: $main-pink;
		margin-top: 25px;
		line-height: 1.6; }

	.answer {
		font-size: 14px;
		line-height: 1.86;
		margin-top: 5px; } }

@media all and (max-width: 1200px) {
	.about-container {
		width: 80%;
		margin: 0px auto; }

	.about-container-left {
		margin-top: 0px; }

	.about-container-left, .about-container-right {
		float: initial;
		.content-wrap, img {
			max-width: 100%;
			float: initial;
			display: block;
			margin: 0px auto;
			padding: 0px;
			.detail {
				max-width: 100%; } }

		img {
			margin-top: 50px; } } }


@media all and (max-width: 600px) {
	.about-container {
		padding: 20px;
		.detail {
			font-size: $larger-font; } }

	.qa-container {
		padding: 20px;
		.answer {
			font-size: $larger-font; } } }
