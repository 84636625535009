.venue-container {
	max-width: 860px;
	padding: 20px;
	min-height: 100px;
	overflow: hidden;

	.title {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.21;
		margin-bottom: 22px; }

	.detail {
		font-size: 14px;
		line-height: 1.86; } }

.venue-container-right {
	img, .content-wrap {
		float: right; }

	img {
		width: 312px;
		height: 208px;
		margin-top: 55px;
		box-shadow: 9px 9px 22px 0 rgba(132, 173, 158, 0.5); }

	.content-wrap {
		max-width: 515px;
		margin-right: 30px; }

	.addr {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.61;
		color: $main-pink;
		margin-bottom: 20px; } }

.venue-container-center {
	text-align: center;

	.content-wrap {
		text-align: left; }

	th {
		padding: 0px 20px 25px 0px;
		font-size: 14px;
		font-weight: 600;
		line-height: 1.5;
		color: $main-pink;
		min-width: 30px; } }

@media all and (max-width: 1200px) {
	.venue-container-right {
		text-align: center;

		img {
			margin-bottom: 45px;
			width: 55%;
			height: auto; }
		img, .content-wrap {
			float: initial; }

		.content-wrap {
			margin: 0px auto;
			text-align: left;
			max-width: 80%; } }

	.venue-container-center {
		.content-wrap {
			margin: 0px auto;
			max-width: 80%; } } }


@media all and (max-width: 600px) {
	.venue-container {
		.detail {
			font-size: $larger-font; } }
	.venue-container-right {
		img {
			width: 85%;
			height: auto; }

		.content-wrap {
			max-width: 90%; }

		.addr {
			margin-bottom: 0px; } }

	.venue-container-center {
		img {
			width: 90%;
			height: auto; }
		.content-wrap {
			max-width: 90%; } } }
