@media all and (min-width: 2000px) {
	footer {
		.footer-block {
			margin: 42px 2vw 60px 2vw; } } }

footer {
	max-width: 1450px;
	min-height: 20px;
	overflow: hidden;
	width: 103vw;

	//min-width: 1450px //temperary

	.footer-block {
		min-height: 100px;
		overflow: hidden;
		width: 20%;
		margin: 42px 0vw 60px 6vw;
		float: left;

		.title {
			font-size: 14px;
			font-weight: 600;
			color: #ec6d65;
			margin-bottom: 22px;

			a {
				color: $main-pink; } }

		p {
			margin-bottom: 6px;
			font-weight: 500;
			font-size: 14px;
			letter-spacing: 1px; }

		img {
			float: left;
			margin-right: 6px; }

		.conduct-detail {
			line-height: 1.5; } }


	.footer-block-s {
		width: 14%; }

	#rightest-footer {
		margin-right: 20px; } }

@media all and (max-width: 900px) {
	footer {
		width: 768px;
		margin-bottom: 100px;


		.small-footer-wrap {
			width: 300px;
			float: left; }

		.large-footer-wrap {
			width: 460px;
			float: left; }

		.footer-block-s {
			float: initial;
			width: 270px; }
		.footer-block {
			float: initial;
			width: 400px;
			margin: 42px 2vw 0px 2vw; } } }

@media all and (max-width: 1200px) {
	footer {
		padding-bottom: 60px;
		padding-left: 5vw;
		width: 94vw !important;
		overflow: hidden;

		.footer-block {
			float: left;
			max-width: 300px;

			.title {
				font-size: 14px;
				font-weight: 600;
				color: #ec6d65;
				margin-bottom: 22px;

				a {
					color: $main-pink; } }

			p {
				margin-bottom: 6px;
				font-weight: 500;
				font-size: 14px;
				letter-spacing: 1px; }

			img {
				float: left;
				margin-right: 6px; }

			.conduct-detail {
				line-height: 1.5; } }

		#rightest-footer {
			margin-right: 20px; } } }


@media all and (max-width: 600px) {
	footer {
		width: 100vw;

		.small-footer-wrap {
			float: initial; }

		.large-footer-wrap {
			float: initial; } } }
