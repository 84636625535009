body {
	background-color: #f7fbf9;
	width: 100vw;
	overflow-x: hidden;
 }	//min-width: 1450px //temperary
.hidden {
	opacity: 0;
	display: none; }

.left-link-fix {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
	position: fixed;
	font-weight: bold;
	letter-spacing: 1px;
	color: $font-dark;
	left: 3px;
	top: 40%;
	z-index: 100000000000;
	@include smooth(0.2s, linear);

	&:hover {
		color: $main-pink; } }

.right-link-fix {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
	position: fixed;
	font-weight: bold;
	letter-spacing: 1px;
	color: $font-dark;
	right: 3px;
	top: 40%;
	z-index: 100000000000;
	@include smooth(0.2s, linear);

	&:hover {
		color: $main-pink; } }

.buy-ticket-btn-fix {
	width: 100vw;
	height: 50px;
	bottom: 0px;
	display: none;
	position: fixed;
	z-index: 1000000000;
	font-family: $primary-font-style;
	background-color: #cce9de;
	border: solid 1px #84ad9e;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1px;
	color: #84ad9e;
	&:hover {
		background-color: #cce9de;
		border: solid 1px #84ad9e;
		color: #84ad9e; } }


.title-controler {
	position: absolute;
	width: 100vw;
	height: 600px;
	z-index: 1;
	opacity: 0;
	background-color: blue; }

.cube-background {
	position: relative;
	top: 0px;
	left: 0px;
	min-height: 100%;
	width: 100%;
	z-index: -1;

	img {
		position: absolute;
		@include smooth(20s, linear); } }
.content-wrap {
	width: 100vw;
	overflow-x: hidden; }

.header-container {
	width: 100vw;
	min-height: 400px;
	overflow: hidden;

	h1 {
		margin: 150px auto 70px;
		font-size: 72px;
		font-weight: 900;
		letter-spacing: 4px;
		color: $main-pink;
		max-width: 600px;
		padding: 100px 0px 200px;
		text-shadow: 0px -0.06px 0 #c35953, 1.15px 0.86px 0 #c35953,2.31px 1.79px 0 #c35953,3.47px 2.73px 0 #c35953; } }
.center {
	margin: 0px auto; }

.content-center {
	text-align: center; }

button {
	background-color: $main-pink;
	color: white;
	height: 46px;
	border: 0;
	font-family: $primary-font-style;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	letter-spacing : 4px;
	font-size : 15px;
	font-weight : 500;

	.en {
		font-weight: 600;
		letter-spacing: 1px; }

	&:after {
		background: #fff;
		content: "";
		height: 200px;
		left: -100px;
		opacity: 0;
		position: absolute;
		top: -50px;
		transform: rotate(35deg);
		transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
		width: 50px; }

	&:active {
		border: none; }

	&:focus {
		outline: none; }

	.insider {
		background-color: #fff;
		width: 100%;
		height: 20px;
		position: absolute;
		opacity: .7;
		left: -135px;
		@include transform(rotateZ(45deg)); }

	&:hover {
		background-color: $main-pink;
		border-color: #fff;
		color: #fff;

		.insider {
			left: 155px;
			@include transition(all 0.3s ease); } } }


@media all and (max-width: 1200px) {
	.left-link-fix {
		display: none; }
	.right-link-fix {
		display: none; }
	.buy-ticket-btn-fix {
		display: initial !important; }
	.title-controler {
		display: none !important;
		height: 0px !important; }

	.header-container {
		max-height: 400px;
		h1 {
			margin: 80px auto 70px; } }

	.header-container-home {
		max-height: 1000px; } }

@media all and (max-width: 600px) {
	.header-container {
		min-height: 250px;
		h1 {
			font-size: 36px;
			padding: 70px 0px 40px;
			margin: 100px auto 0px; } } }
