.fa-facebook:hover {
	color: #3B5998; }

// .fa-github
// 	color: #353937

// 	&:hover
// 		color: black

.fa-twitter:hover {
	color: #179EF2; }

.fa-link:hover {
	color: #EC6D65; }

.speaker-container {
	max-width: 1200px;
	height: 2900px;
	column-count: 4;
	column-gap: 0;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	.margin-top-30 {
		margin-top: 30px; }

	.margin-top-50 {
		margin-top: 50px; }

	.margin-top-80 {
		margin-top: 80px; }

	.margin-top-100 {
		margin-top: 100px; }

	.speaker {
		display: inline-block;
		vertical-align: top;
		width: 200px;
		margin: 20px 0px 90px 20px;

		img {
			width: 200px;
			box-shadow: 9px 9px 22px 0 rgba(132, 173, 158, 0.5); }

		.title {
			font-size: 14px;
			font-weight: 600;
			margin: 25px 0px 13px;
			line-height: 22px; }

		.name {
			font-size: 36px;
			font-weight: 600;
			letter-spacing: 1.1px;
			margin-bottom: 13px;
			width: 120%; }

		.en-name {
			font-size: 24px;
			font-weight: 600; }

		.contact {
			margin-bottom: 11px;
			font-size: 11px;
			letter-spacing: 4px; }

		.intro {
			font-size: 14px;
			line-height: 1.71;
			letter-spacing: 1px;
			display: inline-table; } } }

@media all and (max-width: 1200px) {
	.speaker-container {
		max-width: 840px;
		height: 3200px;
		column-count: 3; }

	.tablet-small-font {
		font-size: 21px !important; } }


@media all and (max-width: 700px) {
	.tablet-small-font {
		font-size: 24px !important; }

	.speaker-container {
		width: 98vw;
		height: auto;
		column-count: 1;
		margin-top: 25px;
		margin-bottom: 60px;
		.speaker {
			display: inline-block;
			vertical-align: top;
			width: 90%;
			margin: 20px 0px 30px 20px;

			img {
				width: 120px;
				float: left;
				margin: 10px 15px 25px 0px; }

			.title {
				font-size: 14px;
				font-weight: 600;
				margin: 15px 0px 11px; }


			.name {
				font-size: 36px;
				font-weight: 600;
				width: 100%;
				letter-spacing: 1.1px;
				margin-bottom: 15px; }


			.en-name {
				font-size: 24px;
				font-weight: 600;
				display: block;
				margin-top: 10px; }


			.contact {
				margin-bottom: 30px; }

			.intro {
				font-size: $larger-font; } } } }
