.sponsor-intro-wrap {
	max-width: 1000px;
	padding: 30px 50px;

	.title {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.21;
		margin-bottom: 20px; }

	.detail {
		font-size: 14px;
		line-height: 1.86;
		margin-bottom: 0px; } }

.sponsor-group {
	max-width: 1000px;
	padding: 50px;
	min-height: 200px;
	overflow: hidden;

	.sponsor-group-title {
		color: $main-pink;
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 1;

		.large {
			font-size: 30px; }

		.black {
			color: $font-dark;
			font-size: 16px;
			font-weight: normal; }

		.italic {
			font-style: italic;
			font-weight: 400;
			letter-spacing: 1px; } }

	.single-sponsor {
		min-height: 100px;
		overflow: hidden;
		margin-top: 40px;

		.img-wrap {
			float: left;
			width: 270px;
			height: 110px;
			background-color: white;
			text-align: center;
			margin: 0px 25px 0px 0px;
			border: solid 1px #dedede;

			img {
				vertical-align: center; } }

		.info-wrap {
			float: left;
			max-width: 700px;

			.title {
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 1px;
				margin-bottom: 10px; }

			.detail {
				font-size: 14px;
				line-height: 1.86; } } } }

@media all and (max-width: 1200px) {
	.sponsor-group {

		.single-sponsor {
			.img-wrap {
				width: 210px;
				height: 110px;

				img {
					width: 100%; } }
			.info-wrap {
				max-width: 62%; } } } }

@media all and (max-width: 700px) {
	.sponsor-intro-wrap {
		padding: 20px;
		margin-top: 25px;
		.detail {
			font-size: $larger-font; } }
	.sponsor-group {
		padding: 20px;
		.sponsor-group-title {
			.black {
				display: block;
				margin-top: 10px; } }
		.single-sponsor {
			.img-wrap {
				margin: 20px auto 15px; }

			.info-wrap {
				float: initial;
				display: inline-table;
				max-width: 95%;
				font-size: $larger-font; } } } }
