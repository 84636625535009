.album-container {
  margin-bottom: 50px;

  .albums-item {
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform .3s; }

    .detail {
      width: 100%;
      padding: 20px 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      color: #ffffff; }

    &:hover {
      img {
        transform: scale(1.1); } } }

  .album-item {
    margin-bottom: 30px; } }
